import React from 'react';

const ThankYou = props => (
  <svg fill="none" {...props}>
    <g clipPath="url(#prefix__clip0)">
      <path fill="#2D3B4F" d="M0 0h1035.48v627.636H0z" />
      <path
        d="M687 214.649L336.163 0l-53.388 87.261a65.99 65.99 0 01-68.855 112.541l-17.325 28.317H344.48v179.073l360.309 220.444 75.412-123.257H687v-289.73z"
        fill="#F2F2F2"
      />
      <path
        d="M514.424 480.085c35.091 0 63.537-4.398 63.537-9.822 0-5.424-28.446-9.822-63.537-9.822-35.09 0-63.537 4.398-63.537 9.822 0 5.424 28.447 9.822 63.537 9.822zM516.48 383.279l64.018-73.234a51 51 0 00-72.915-71.326 50.994 50.994 0 00-36.225-14.539 50.986 50.986 0 00-35.896 15.334 50.99 50.99 0 00-14.539 36.224 51.005 51.005 0 0015.334 35.896l71.223 71.645"
        fill="#007F00"
      />
      <path
        d="M346.48 227.119H1v313.26h345.48v-313.26zM1034.48 190.119H689v313.26h345.48v-313.26z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M249.48 187.279c23.748 0 43-19.252 43-43s-19.252-43-43-43-43 19.252-43 43 19.252 43 43 43z"
        fill="#007F00"
      />
      <path
        d="M351.48 378.279s54-19 77 7 87-15 87-15M790.48 385.279s-10-24-78-2-164 21-188-13l64.018-73.234a51 51 0 00-72.915-71.326 51.002 51.002 0 00-86.66 37.019 51 51 0 0015.334 35.896l71.223 71.645M522.536 471.922c35.09 0 63.537-4.397 63.537-9.821 0-5.425-28.447-9.822-63.537-9.822-35.091 0-63.537 4.397-63.537 9.822 0 5.424 28.446 9.821 63.537 9.821z"
        stroke="#3F3D56"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M515.776 538.924c27.625 0 50.019-3.461 50.019-7.732 0-4.27-22.394-7.732-50.019-7.732-27.624 0-50.018 3.462-50.018 7.732 0 4.271 22.394 7.732 50.018 7.732zM515.776 595.279c18.665 0 33.797-2.339 33.797-5.224 0-2.885-15.132-5.224-33.797-5.224s-33.796 2.339-33.796 5.224c0 2.885 15.131 5.224 33.796 5.224z"
        fill="#007F00"
      />
      <path
        d="M522.536 532.036c27.624 0 50.018-3.462 50.018-7.732s-22.394-7.732-50.018-7.732c-27.625 0-50.019 3.462-50.019 7.732s22.394 7.732 50.019 7.732zM522.536 590.095c18.665 0 33.796-2.339 33.796-5.225 0-2.885-15.131-5.224-33.796-5.224-18.666 0-33.797 2.339-33.797 5.224 0 2.886 15.131 5.225 33.797 5.225z"
        stroke="#3F3D56"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M202.598 390.736c18.365 0 33.253-14.888 33.253-33.252 0-18.365-14.888-33.252-33.253-33.252-18.364 0-33.252 14.887-33.252 33.252 0 18.364 14.888 33.252 33.252 33.252z"
        fill="#2F2E41"
      />
      <path
        d="M186.725 374.283s-10.831 23.983-13.925 24.756c-3.095.774 25.529 14.699 25.529 14.699s5.416-29.398 7.737-30.945c2.321-1.547-19.341-8.51-19.341-8.51z"
        fill="#9F616A"
      />
      <path
        d="M202.971 392.077c12.391 0 22.436-10.045 22.436-22.436 0-12.39-10.045-22.435-22.436-22.435-12.39 0-22.435 10.045-22.435 22.435 0 12.391 10.045 22.436 22.435 22.436z"
        fill="#9F616A"
      />
      <path
        d="M221.76 540.379h-95.4c3.19-11.59 6.6-23.08 8.52-29.46.97-3.2 1.56-5.12 1.56-5.12s71.95 15.47 73.49 16.25a18.228 18.228 0 012.29 3.02c2.25 3.3 5.84 8.97 9.54 15.31z"
        fill="#2F2E41"
      />
      <path
        d="M103.173 321.676l-23.21-35.587s5.74-37.331-1.996-30.368c-7.736 6.962-10.184 35.08-10.184 35.08l26.88 40.932 8.51-10.057zM313.601 386.661l20.888-1.547s24.756-26.304 26.303-17.794c1.547 8.51-20.114 27.077-20.114 27.077l-28.625 3.095 1.548-10.831z"
        fill="#9F616A"
      />
      <path
        d="M161.195 394.397v-7.736s-20.114-13.152-19.341-15.473c.774-2.32-1.547-3.868-1.547-3.868s-2.321-3.094-2.321-3.868c0-.773-3.094-1.547-3.094-1.547l-19.341-22.435s-10.831-21.662-13.152-20.888c-2.321.773-14.699 5.415-13.151 8.51 1.547 3.094 44.87 69.626 44.87 69.626s2.321 14.699 6.189 14.699 20.888-17.02 20.888-17.02zM213.029 403.681s17.019-.774 17.793 0c.774.774 11.604 0 11.604 0s29.398-6.963 31.719-9.284c2.321-2.32 41.003-12.378 42.55-7.736 1.547 4.642 5.415 13.925 1.547 15.473-3.868 1.547-108.308 27.85-108.308 27.85l3.095-26.303z"
        fill="#3F3D56"
      />
      <path
        d="M190.966 336.246c7.599 0 13.76-6.161 13.76-13.76s-6.161-13.76-13.76-13.76-13.759 6.161-13.759 13.76 6.16 13.76 13.759 13.76z"
        fill="#2F2E41"
      />
      <path
        d="M172.62 315.033a13.761 13.761 0 0112.326-13.685 13.76 13.76 0 100 27.37 13.761 13.761 0 01-12.326-13.685zM206.467 364.193c12.665 0 22.932-6.16 22.932-13.76 0-7.599-10.267-13.759-22.932-13.759-12.666 0-22.933 6.16-22.933 13.759 0 7.6 10.267 13.76 22.933 13.76z"
        fill="#2F2E41"
      />
      <path
        d="M180.149 386.274s-6.962 5.416-8.51 6.189c-1.547.774-10.831-6.962-12.378-6.962-1.547 0-21.662 23.209-21.662 23.209s3.739 4.305 0 26.303c-1.401 8.25-4.183 43.521-4.183 48.163 0 4.641-15.157 16.048-1.232 26.106 13.925 10.057 80.458 20.888 81.231 14.699.774-6.189-7.736-22.436-3.868-30.172 3.868-7.736 3.868-36.361 3.868-36.361l4.642-30.171 3.868-23.983s-18.18-5.028-19.727-5.028c-1.548 0-8.51 10.83-22.049-11.992z"
        fill="#3F3D56"
      />
      <path
        d="M877.288 312.385l-15.831.614.789 20.353 15.831-.613-.789-20.354z"
        fill="#007F00"
      />
      <path
        d="M805.876 383.668s-21.924-10.474-21.573-1.428c.351 9.046 22.099 14.997 22.099 14.997l-.526-13.569z"
        fill="#FFB9B9"
      />
      <path
        d="M907.82 503.379H803.83c2.17-10.18 6.3-22.46 9.53-31.31 2.56-7 4.54-11.86 4.54-11.86 11.81-13.32 28.95-13.66 45.03-10.37 1.92.39 3.83.83 5.71 1.32.22.05.44.11.66.17 3.76.97 7.41 2.09 10.86 3.21 5.95 1.94 11.32 3.9 15.66 5.23a38.584 38.584 0 006.7 1.57c.498.053.999.073 1.5.06 1.72-.07 2.91 1.78 3.68 5.01 1.74 7.29 1.34 21.65.12 36.97z"
        fill="#2F2E41"
      />
      <path
        d="M887.307 293.311s12.053 18.784 16.751 23.132c4.699 4.347-8.52 13.92-8.52 13.92l-23.658 3.182s.297-21.528-.921-23.746c-1.219-2.218 16.348-16.488 16.348-16.488z"
        fill="#FFB9B9"
      />
      <path
        d="M866.743 317.89s4.874 8.871 9.397 8.695c4.523-.175 27.918-10.142 28.917-13.578 1-3.437 7.75 24.613 7.75 24.613L855.56 438.365l-13.964-9.651 6.766-59.15 5.557-31.925 12.824-19.749z"
        fill="#007F00"
      />
      <path
        d="M907.169 408.052a31.685 31.685 0 00-3.512 14.858c.009.226.018.452.038.678.541 10.466 4.397 22.57 5.78 31.372 1.004 6.314.718 10.923-2.977 12.006-8.959 2.613 1.306 4.479-33.353 16.016-34.659 11.536-40.708 1.578-40.795-.683-.088-2.262 4.908-19.443 3.733-20.529-1.174-1.087-16.435 14.226-21.046 12.14-4.61-2.086-.526-13.569-.526-13.569s10.387-24.184 10.168-29.838c-.22-5.654 15.733-32.32 15.733-32.32l10.851-70.633s4.172-9.222 9.782-10.572a13.899 13.899 0 0110.265 1.867l-9.388 20.749-9.904 36.623 1.657 13.525s11.517-24.228 19.082-33.582c7.564-9.353 23.693-31.495 23.693-31.495s3.765-12.705.923-16.502c-2.843-3.808 11.857-4.378 18.125 11.234 3.321 8.274 8.715 20.612 12.093 32.1 3.011 10.178 4.433 19.692 1.45 25.13-4.558 8.308-16.026 19.228-21.872 31.425z"
        fill="#575A89"
      />
      <path
        opacity={0.1}
        d="M929.041 376.627c-4.558 8.308-16.026 19.228-21.872 31.425-1.527-5.501-3.39-11.193-3.39-11.193s-7.145-38.227 13.989-48.106c3.502-.339 7.004.64 9.823 2.744 3.011 10.178 4.433 19.692 1.45 25.13z"
        fill="#000"
      />
      <path
        d="M845.145 374.218l-17.916 5.225-24.877.965-2.56 21.616 40.883 2.944s10.212-28.708 4.47-30.75z"
        fill="#575A89"
      />
      <path
        d="M874.361 446.898s-23.969 3.977-18.498 11.19c5.471 7.213 26.704-.37 26.704-.37l-8.206-10.82z"
        fill="#FFB9B9"
      />
      <path
        opacity={0.1}
        d="M903.695 423.588c.541 10.466 4.397 22.57 5.78 31.372-7.636 7.227-14.897 13.601-17.633 13.707-5.654.219-14.744-.561-18.093.702-3.348 1.262-5.312-20.179-5.312-20.179s1.999-6.872 10.913-10.615c6.017-2.532 17.551-9.401 24.345-14.987z"
        fill="#000"
      />
      <path
        d="M917.505 341.968s19.136-3.007 18.102 28.742c-1.034 31.749 2.017 52.016-5.679 57.976-7.696 5.961-32.696 32.977-38.349 33.196-5.654.22-14.744-.56-18.093.702-3.348 1.262-5.312-20.178-5.312-20.178s1.998-6.873 10.913-10.616c8.915-3.743 29.917-17.014 29.741-21.537-.175-4.524-5.312-20.179-5.312-20.179s-7.145-38.227 13.989-48.106z"
        fill="#575A89"
      />
      <path
        d="M870.433 321.125c13.749 0 24.895-11.146 24.895-24.895 0-13.75-11.146-24.896-24.895-24.896-13.75 0-24.896 11.146-24.896 24.896 0 13.749 11.146 24.895 24.896 24.895z"
        fill="#FFB9B9"
      />
      <path
        d="M853.67 260.547a4.81 4.81 0 01-2.093 1.051 1.493 1.493 0 01-1.731-1.199 6.213 6.213 0 01-2.375 3.046c-1.163.606-2.962.098-3.129-1.203a5.488 5.488 0 01-1.264 2.667 1.892 1.892 0 01-2.659.089c.509 2.783.195 5.645.354 8.47.16 2.825.914 5.82 3.048 7.677 3.112 2.709 7.793 2.041 11.887 1.527a3.615 3.615 0 011.863.102c1.533.653 1.464 2.787 1.484 4.454a12.372 12.372 0 0010.836 11.75 6.199 6.199 0 004.61-1.125c1.162-.985 1.756-2.591 3.088-3.331 2.167-1.204 4.795.552 6.379 2.458 1.584 1.907 3.145 4.252 5.6 4.601 3.245.462 5.717-2.846 6.93-5.892a34.341 34.341 0 00-.682-26.689c-6.778-14.773-29.929-17.11-42.146-8.453z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h1035.48v627.636H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ThankYou;
